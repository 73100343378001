import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import Email from '@material-ui/icons/Email';
import Person from '@material-ui/icons/Person';
import CardId from '@material-ui/icons/CreditCard';
import Name from '@material-ui/icons/VerifiedUser';
import Phone from '@material-ui/icons/Phone';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
// core components
import GridItem from 'components/core/Grid/GridItem';
import Button from 'components/core/CustomButtons/Button';
import Card from 'components/core/Card/Card';
import CardHeader from 'components/core/Card/CardHeader';
import CardBody from 'components/core/Card/CardBody';
import UsersStyle from 'assets/jss/views/UserProfileStyle';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { request, URLs } from '../../request';
import PermissionForm from './PermissionForm';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      username: '',
      name: '',
      mobile: '',
      id_number: '',
      role: 0,
      open: false,
      error: false,
      permissions: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleMobileChange = this.handleMobileChange.bind(this);
    this.handleIdChange = this.handleIdChange.bind(this);
    this.handleAddUserSubmit = this.handleAddUserSubmit.bind(this);
    this.handleClose = () => {
      this.setState({ open: false });
      this.setState({ error: false });
    };
  }

  handleChange = name => event => {
    const checked = event.target.checked;
    /* eslint-disable-next-line */
    let permissions = [...this.state.permissions];
    if (checked) {
      permissions.push(name);
    } else {
      permissions = permissions.filter(p => p !== name);
    }
    this.setState({ [name]: checked, permissions });
  };

  handleUserRoleChange = e => {
    const value = Number(e.target.value);
    if (e.target.checked) {
      this.setState({ role: value });
    } else {
      this.setState({ role: 0 });
    }
  };

  handleEmailChange(evt) {
    this.setState({ email: evt.target.value });
  }

  handleUsernameChange(evt) {
    this.setState({ username: evt.target.value });
  }

  handleNameChange(evt) {
    this.setState({ name: evt.target.value });
  }

  handleMobileChange(evt) {
    this.setState({ mobile: evt.target.value });
  }

  handleIdChange(evt) {
    this.setState({ id_number: evt.target.value });
  }

  handleAddUserSubmit() {
    const email = this.state.email;
    const username = this.state.username;
    const name = this.state.name;
    const mobile = this.state.mobile;
    const id = this.state.id_number;
    const permissions = this.state.permissions;
    const role = this.state.role;

    const bodyParameters = {
      name,
      email,
      username,
      mobile,
      id_number: id,
      permissions,
      role,
    };

    request
      .POST(URLs.backoffice.USERS, bodyParameters)
      .then(() => {
        this.setState({ open: true });
        this.props.history.push('/users');
      })
      .catch(error => {
        if (error.response.status === 422) this.setState({ error: true });
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {/* eslint-disable-next-line no-nested-ternary */}
        <Grid container>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info">
                <h4 className={classes.cardTitleWhite}>إضافة مستخدم</h4>
                <p className={classes.cardCategoryWhite}>بإمكانك هنا إضافة مستخدمين جدد للنظام</p>
              </CardHeader>
              <CardBody>
                <Grid container justify="center">
                  <GridItem xs={6} sm={6} md={6}>
                    <TextField
                      label="اسم المستخدم"
                      id="username"
                      type="text"
                      fullWidth
                      margin="normal"
                      onChange={this.handleUsernameChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Name />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <TextField
                      label="البريد الإلكتروني"
                      id="email"
                      type="email"
                      fullWidth
                      margin="normal"
                      onChange={this.handleEmailChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <TextField
                      label="الإسم"
                      id="name"
                      type="text"
                      fullWidth
                      margin="normal"
                      onChange={this.handleNameChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Person />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <TextField
                      label="رقم الهاتف"
                      id="mobile"
                      type="number"
                      fullWidth
                      margin="normal"
                      onChange={this.handleMobileChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Phone />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </GridItem>
                </Grid>
                <Grid>
                  <GridItem xs={6} sm={6} md={6}>
                    <TextField
                      label="رقم الأحوال"
                      id="id_number"
                      type="number"
                      fullWidth
                      margin="normal"
                      onChange={this.handleIdChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CardId />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </GridItem>
                </Grid>
                <br />
                <Grid container>
                  <div>
                    <GridItem xs={12} sm={12} md={12} style={{ margin: '0 0 20px 0' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="HRSD_VP"
                            value={1}
                            checked={this.state.role === 1}
                            onChange={this.handleUserRoleChange}
                          />
                        }
                        label="ممثل وزارة الموارد البشرية"
                      />
                    </GridItem>
                    {this.state.role === 0 && (
                      <PermissionForm
                        permissions={this.state.permissions}
                        onChange={this.handleChange}
                      />
                    )}
                  </div>
                  <GridItem xs={12}>
                    <Button color="info" onClick={this.handleAddUserSubmit}>
                      حفظ
                    </Button>
                  </GridItem>
                </Grid>
                <Snackbar
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  open={this.state.open}
                  autoHideDuration={6000}
                  onClose={this.handleClose}
                  ContentProps={{
                    'aria-describedby': 'message-id',
                  }}
                  message={<span id="message-id">تم إنشاء المستخدم</span>}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      className={classes.close}
                      onClick={this.handleClose}
                    >
                      <CloseIcon />
                    </IconButton>,
                  ]}
                />
                <Snackbar
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  open={this.state.error}
                  autoHideDuration={6000}
                  onClose={this.handleClose}
                  ContentProps={{
                    'aria-describedby': 'message-id',
                  }}
                  message={<span id="message-id">الرجاء التحقق من البيانات المدخلة.</span>}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      className={classes.close}
                      onClick={this.handleClose}
                    >
                      <CloseIcon />
                    </IconButton>,
                  ]}
                />
              </CardBody>
            </Card>
          </GridItem>
        </Grid>
      </div>
    );
  }
}

Users.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

const mapStateToProps = state => {
  return {
    access_token: state,
  };
};
export default compose(
  withStyles(UsersStyle),
  connect(mapStateToProps),
)(Users);
