import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
// import ZoomIn from '@material-ui/icons/ZoomIn';
// core components
import { connect } from 'react-redux';
import compose from 'recompose/compose';
// import axios from 'axios';
import { request, URLs } from '../../request';
// Styles
import withStyles from '@material-ui/core/styles/withStyles';
import QualityRequestsStyle from 'assets/jss/views/QualityRequestsStyle';
import { withRouter } from 'react-router-dom';
import CurrentRequest from '../../components/Requests/CurrentRequest';
import ModalButton from '../../components/core/CustomButtons/ModalButton';
import BlockForm from '../../components/Forms/BlockForm';
import RejectForm from '../../components/Forms/RejectForm';

class QualityCurrentRequest extends React.Component {
  handleRejection = reasons => {
    const bodyParameters = {
      id: this.props.qualityRequest.id,
      processing_request_id: this.props.requestId,
      rejection_reasons: reasons,
    };

    request.POST(URLs.backoffice.PROCESS_QUALITY_REJECT, bodyParameters).then(() => {
      this.props.history.push('/quality');
    });
  };

  handleBlock = (reason, notes, file) => {
    const bodyParameters = {
      id: this.props.qualityRequest.id,
      reference_id: this.props.refrenceId,
      id_number: this.props.userInformation.id_number,
      blocking_reason_id: reason,
      notes,
    };
    if (file.name.length > 0) {
      bodyParameters.file = file;
    }

    request.POST(URLs.backoffice.BLOCKED_USERS, bodyParameters).then(() => {
      this.props.history.push('/quality');
    });
  };

  handleSubmit = (option, range, type) => {
    const bodyParameters = {
      id: this.props.qualityRequest.id,
      processing_request_id: this.props.requestId,
      financial_option: option,
      range,
      range_type: type,
    };

    request.POST(URLs.backoffice.PROCESS_QUALITY_ACCEPT, bodyParameters).then(() => {
      this.props.history.push('/quality');
    });
  };

  render() {
    const {
      classes,
      userInformation,
      visaInformation,
      attachments,
      category,
      reasons,
      notes,
      isDisable,
      blockReasons = [],
      rejectReasons = [],
      refrenceId,
    } = this.props;
    const selectReason = [];
    rejectReasons.map(item => selectReason.push({ name: item.label, id: item.id }));
    const Actions = (
      <React.Fragment>
        <ModalButton label="حظر" type="danger" classes={classes}>
          <BlockForm blockReasons={blockReasons} classes={classes} onSubmit={this.handleBlock} />
        </ModalButton>
        <ModalButton label="رفض" type="danger" classes={classes} outline>
          <RejectForm
            classes={classes}
            onSubmit={this.handleRejection}
            rejectReasons={rejectReasons}
            selectReason={selectReason}
          />
        </ModalButton>
      </React.Fragment>
    );

    return (
      <CurrentRequest
        attachments={attachments}
        classes={classes}
        disabilityDetails={{
          isDisable,
          responsibleId: isDisable ? JSON.parse(visaInformation.isDisable).Responsible_ID : null,
          responsibleName: isDisable
            ? JSON.parse(visaInformation.isDisable).Responsible_Name
            : null,
        }}
        userDetails={userInformation}
        onSubmit={this.handleSubmit}
        title="طلب جودة"
        category={category}
        escalationNotes={notes}
        reasons={reasons}
        id={refrenceId}
        actions={Actions}
        userIdNumber={userInformation.id_number}
        dateCreated={this.props.dateCreated}
        dateEscalated={this.props.dateEscalated}
        requestId={this.props.requestId}
        showLogs
      />
    );
  }
}

QualityCurrentRequest.propTypes = {
  attachments: PropTypes.array,
  blockReasons: PropTypes.array,
  category: PropTypes.object,
  classes: PropTypes.object.isRequired,
  dateCreated: PropTypes.string,
  dateEscalated: PropTypes.string,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  isDisable: PropTypes.bool.isRequired,
  notes: PropTypes.string,
  qualityRequest: PropTypes.object,
  reasons: PropTypes.array,
  refrenceId: PropTypes.string,
  rejectReasons: PropTypes.array,
  requestId: PropTypes.string,
  userInformation: PropTypes.object,
  visaInformation: PropTypes.object,
};
const mapStateToProps = state => {
  return {
    access_token: state,
  };
};
export default compose(
  withStyles(QualityRequestsStyle),
  connect(mapStateToProps),
  withRouter,
)(QualityCurrentRequest);
