import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import GridItem from 'components/core/Grid/GridItem';
import Button from 'components/core/CustomButtons/Button';
import SubmitButton from 'components/core/CustomButtons/SubmitButton';
import Card from 'components/core/Card/Card';
import CardHeader from 'components/core/Card/CardHeader';
import CardBody from 'components/core/Card/CardBody';

class BlockForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: 0,
      note: '',
      autoUnblockMonths: 0,
      blockingFileUploaded: { name: '', base64: '' },
    };
  }

  handleBlockReasonChange = e => {
    const blockReasonId = e.target.value;
    this.setAutoUnblockMonths(blockReasonId);
    this.setState({ reason: e.target.value });
  };

  handleBlockNoteChange = e => this.setState({ note: e.target.value });

  handleBlock = () => {
    this.props.onSubmit(this.state.reason, this.state.note, this.state.blockingFileUploaded);
    this.props.onCancel();
  };

  setAutoUnblockMonths = blockReasonId => {
    // eslint-disable-next-line array-callback-return
    this.props.blockReasons.map(el => {
      if (el.id === blockReasonId) {
        this.setState({ autoUnblockMonths: el.auto_unblock_months });
      }
    });
  };

  handleFileUpload = ({ target }) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(target.files[0]);
    fileReader.onload = e => {
      this.setState({
        blockingFileUploaded: { name: target.files[0].name, base64: e.target.result },
      });
    };
  };

  render() {
    const { classes, blockReasons = [], onCancel } = this.props;

    const { autoUnblockMonths, blockingFileUploaded } = this.state;
    return (
      <Card>
        <CardHeader className={classes.header}>
          <p className={classes.title}>حظر مستخدم</p>
        </CardHeader>
        <CardBody className={classes.modalContainer}>
          <GridItem xs={12} sm={12} md={12}>
            <FormControl className={classes.row}>
              <label className={classes.mLabel}>سبب الحظر</label>
              <Select
                onChange={this.handleBlockReasonChange}
                value={this.state.reason}
                input={<OutlinedInput labelWidth={0} name="type" />}
                className={classes.blockReasonSelect}
              >
                {blockReasons.map(item => (
                  <MenuItem id={item.id} value={item.id}>
                    {item.reason}
                  </MenuItem>
                ))}
              </Select>
              {autoUnblockMonths > 0 && (
                <b>سيتم الغاء الحظر تلقائيا بعد ({autoUnblockMonths}) شهر</b>
              )}
            </FormControl>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <FormControl className={classes.row}>
              <label className={classes.mLabel}>معلومات اضافية</label>
              <TextField
                multiline
                rows={4}
                rowsMax={8}
                variant="outlined"
                className={classes.note}
                value={this.state.note}
                onChange={this.handleBlockNoteChange}
              />
            </FormControl>
          </GridItem>
          <GridItem style={{ margin: '0 0 30px 0' }}>
            <input
              accept="image/jpeg,application/pdf"
              className={classes.input}
              id="contained-button-file"
              style={{ display: 'none' }}
              type="file"
              onChange={this.handleFileUpload}
            />
            <label htmlFor="contained-button-file">
              <Button variant="contained" component="span" className={classes.button}>
                ارفاق ملفات داعمة
              </Button>
            </label>
            <b style={{ margin: '0 20px' }}>{blockingFileUploaded.name}</b>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <div className={classes.modalFooter}>
              <Button color="danger" outline onClick={onCancel}>
                الغاء
              </Button>
              <SubmitButton
                color="primary"
                onClick={this.handleBlock}
                disabled={!this.state.reason || !this.state.note}
              >
                إرسال
              </SubmitButton>
            </div>
          </GridItem>
        </CardBody>
      </Card>
    );
  }
}

BlockForm.propTypes = {
  blockReasons: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

export default BlockForm;
